import { Injectable } from '@angular/core';
import Amplify, { API } from 'aws-amplify';
import { aws_exports } from './aws-exports';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  defaultError: string;

  constructor() {
    Amplify.configure(aws_exports);
    this.defaultError = 'Oops! There was an issue. Please try again.';
  }

  /**
 * Call a Lambda function and return the response
 * @param {string} func name of Lambda function to call
 * @param {object} body object to send to Lambda as data payload
 */
  async invokeLambda(func: string, body?: object): Promise<object> {
    if (!body) body = {};
    console.log(`Invoking ${func}`);
    try {
      const result = await API.post('Lambda', `${func}/invocations`, { body });
      console.log(`${func} result below...`);
      console.log(result);
      if (result['errorMessage']) {
        console.log(`${func} call succeeded with error`);
        return { success: false, message: result['errorMessage'] };
      } else {
        console.log(`${func} call succeeded with success`);
        return { success: true, response: result };
      }
    } catch (error) {
      console.log(`${func} call failed`);
      console.error(error);
      return { success: false, message: this.defaultError };
    }
  }

}
