export const aws_exports: object = {
    Auth: {
      identityPoolId: 'us-east-1:45e262df-d7f7-4f7d-a3a5-56481152f2ce',
      region: 'us-east-1',
      userPoolId: 'us-east-1_W3Pnj5yG4',
      userPoolWebClientId: 'q1l9e8ucmcqiqvjrha0uesaui'
    },
    API: {
      endpoints: [
        {
          name: "Lambda",
          endpoint: "https://lambda.us-east-1.amazonaws.com/2015-03-31/functions/",
          service: "lambda",
          region: "us-east-1"
        }
      ]
    }
  };
  